import {useSettings} from '@wix/tpa-settings/dist/src/hooks'
import React from 'react'
import Location from 'wix-ui-icons-common/LocationSmall'
import settingsParams from '../../settingsParams'
import {Tags} from '../tags'
import {DH} from '../../data-hooks'
import {Description} from './description'
import {DateDivider, ItemDivider} from './divider'
import {classes} from './agenda.st.css'
import {AgendaProps} from '.'

export const Agenda: React.FC<AgendaProps> = props => {
  const {tagClicked} = props
  const days = Object.values(props.days)
  const settings = useSettings()
  const showDuration = settings.get(settingsParams.showDuration)

  return (
    <div className={classes.agenda}>
      {days.map(day => {
        return (
          <div key={day.title} className={classes.item}>
            <div className={classes.dateDivider}>{day.title}</div>
            <DateDivider />
            <div>
              {day.items.map((item, index) => (
                <div key={index}>
                  <div className={classes.itemContent}>
                    <div className={classes.timeContainer}>
                      <div className={classes.timeSlot}>{item.formattedTimeSlot}</div>
                      {showDuration && <div className={classes.duration}>{item.formattedDuration}</div>}
                    </div>
                    <div className={classes.content}>
                      <div className={classes.title}>{item.name}</div>
                      {item.location && (
                        <div className={classes.location} data-hook={DH.LOCATION}>
                          <Location data-hook={DH.LOCATION_ICON} />
                          <div>{item.location}</div>
                        </div>
                      )}
                      {item.tags && <Tags tags={item.tags} onClick={tagClicked} />}
                      {item.description && <Description text={item.description} />}
                    </div>
                  </div>
                  {index !== day.items.length - 1 && <ItemDivider />}
                </div>
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}
