import React from 'react'
import {translate, InjectedExperimentsProps} from 'yoshi-flow-editor-runtime'
import {useSettings} from '@wix/tpa-settings/dist/src/hooks'
import {DH} from '../data-hooks'
import type {SchedulePlace, ScheduleTag} from '../services/schedule'
import settingsParams, {HEADER_TEXT_ALIGNMENT} from '../settingsParams'
import type {ScheduleDays} from '../types'
import type {TagClicked} from '../actions/selected-tags'
import {Button} from './button'
import {Tags} from './tags'
import {Agenda} from './agenda'
import {Places} from './places'
import {classes} from './widget.st.css'

type WidgetProps = InjectedExperimentsProps &
  EventScheduleProps &
  EventScheduleActions & {
    className?: string
    t: Function
    id: string
  }

export interface EventScheduleProps {
  formattedEventDateAndLocation: string
  eventTitle: string
  places: SchedulePlace[]
  tags: ScheduleTag[]
  days: ScheduleDays
  eventDetailsUrl: string
}

export interface EventScheduleActions {
  tagClicked: TagClicked
}

export default translate()(
  ({
    t,
    id,
    eventDetailsUrl,
    eventTitle,
    formattedEventDateAndLocation,
    places,
    tags,
    days,
    tagClicked,
  }: WidgetProps) => {
    const settings = useSettings()
    const showDateAndLocation = settings.get(settingsParams.showDateAndLocation)
    const showFilters = settings.get(settingsParams.showFilters)
    const headerTextAlignment = settings.get(settingsParams.headerTextAlignment)

    return (
      <div className={id}>
        <div className={classes.root}>
          <div className={classes[HEADER_TEXT_ALIGNMENT[headerTextAlignment]]}>
            {showDateAndLocation && (
              <div className={classes.headerDateLocation}>
                <span data-hook={DH.EVENT_TITLE}>{eventTitle}</span>
                <span> | </span>
                <span>{formattedEventDateAndLocation}</span>
              </div>
            )}
            <div className={classes.headerTitle}>{settings.get(settingsParams.scheduleTitleText)}</div>
            <Button eventDetailsUrl={eventDetailsUrl} />
          </div>
          {showFilters && (
            <div className={classes.filters}>
              <Places places={places} />
              <Tags tags={tags} onClick={tagClicked} />
            </div>
          )}
          <Agenda days={days} tagClicked={tagClicked} />
        </div>
      </div>
    )
  },
)
