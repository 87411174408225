import {StyleParamType, wixColorParam, wixFontParam, createStylesParams} from '@wix/tpa-settings'

type IStylesParams = {
  backgroundColor: StyleParamType.Color
  headerTextColor: StyleParamType.Color
  headerTextFont: StyleParamType.Font
  headerTitleColor: StyleParamType.Color
  headerTitleFont: StyleParamType.Font
  dateDividerTextColor: StyleParamType.Color
  dateDividerTextFont: StyleParamType.Font
  dateDividerColor: StyleParamType.Color
  dateDividerWidth: StyleParamType.Number
  timeSlotTextColor: StyleParamType.Color
  timeSlotTextFont: StyleParamType.Font
  durationTextColor: StyleParamType.Color
  durationTextFont: StyleParamType.Font
  scheduleItemDividerColor: StyleParamType.Color
  scheduleItemDividerWidth: StyleParamType.Number
  scheduleItemTitleTextColor: StyleParamType.Color
  scheduleItemTitleTextFont: StyleParamType.Font
  scheduleItemLocationTextColor: StyleParamType.Color
  scheduleItemLocationTextFont: StyleParamType.Font
  scheduleItemDescriptionTextColor: StyleParamType.Color
  scheduleItemDescriptionTextFont: StyleParamType.Font
  tagTextColor: StyleParamType.Color
  tagColor: StyleParamType.Color
  buttonTextFont: StyleParamType.Font
  hollowButtonTextColor: StyleParamType.Color
  buttonBorderColor: StyleParamType.Color
  fullButtonTextColor: StyleParamType.Color
  buttonBackgroundColor: StyleParamType.Color
  buttonBorderWidth: StyleParamType.Number
  buttonBorderRadius: StyleParamType.Number
}

export default createStylesParams<IStylesParams>({
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  headerTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 18,
    }),
  },
  headerTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 60,
    }),
  },
  dateDividerTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  dateDividerTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 20,
    }),
  },
  timeSlotTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  timeSlotTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  durationTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  durationTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  scheduleItemDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleItemDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  dateDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  scheduleItemTitleTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleItemTitleTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  scheduleItemLocationTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleItemLocationTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  scheduleItemDescriptionTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleItemDescriptionTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  dateDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  tagTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  tagColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  fullButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  hollowButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
      // 14
      // 20
    }),
  },
  buttonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  buttonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
})
