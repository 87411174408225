import {useSettings} from '@wix/tpa-settings/dist/src/hooks'
import React, {useState} from 'react'
import {Text} from 'wix-ui-tpa/Text'
import {TEXT_BUTTON_PRIORITY, TextButton} from 'wix-ui-tpa/TextButton'
import {DH} from '../../../data-hooks'
import settingsParams, {isFullButtonStyle} from '../../../settingsParams'
import {classes} from './description.st.css'

interface DescriptionProps {
  text: string
}

export const Description: React.FC<DescriptionProps> = ({text}) => {
  const [opened, toggle] = useState(false)
  const settings = useSettings()
  const descriptionButtonText = settings.get(settingsParams.readMore)
  const buttonStyle = settings.get(settingsParams.buttonStyle)
  const full = isFullButtonStyle(buttonStyle)

  return (
    <div className={classes.container}>
      <TextButton
        className={full ? classes.full : classes.hollow}
        priority={TEXT_BUTTON_PRIORITY.primary}
        data-hook={DH.DESCRIPTION_BUTTON}
        onClick={() => toggle(!opened)}
      >
        {descriptionButtonText}
      </TextButton>
      {opened && (
        <Text className={classes.text} data-hook={DH.DESCRIPTION}>
          <div dangerouslySetInnerHTML={{__html: text}} />
        </Text>
      )}
    </div>
  )
}
