import {useSettings} from '@wix/tpa-settings/dist/src/hooks'
import React from 'react'
import {Button as UIButton, PRIORITY} from 'wix-ui-tpa/Button'
import settingsParams, {isFullButtonStyle} from '../../settingsParams'
import {classes} from './button.st.css'

interface ButtonProps {
  eventDetailsUrl: string
}

export const Button: React.FC<ButtonProps> = ({eventDetailsUrl}) => {
  const settings = useSettings()
  const showButton = settings.get(settingsParams.showButton)
  if (!showButton) {
    return null
  }
  const buttonStyle = settings.get(settingsParams.buttonStyle)
  const full = isFullButtonStyle(buttonStyle)

  return (
    <UIButton
      className={full ? classes.full : classes.hollow}
      upgrade
      as="a"
      href={eventDetailsUrl}
      priority={full ? PRIORITY.primary : PRIORITY.secondary}
    >
      {settings.get(settingsParams.buttonText)}
    </UIButton>
  )
}
