import React from 'react'
import {SIZE, SKIN} from 'wix-ui-tpa/dist/src/components/Tags/constants'
import {Tags as WixTags} from 'wix-ui-tpa/Tags'
import {classes} from './tags.st.css'
import {TagsProps} from './index'

export const Tags: React.FC<TagsProps> = ({onClick, tags}) => {
  if (!tags.length) {
    return null
  }

  return (
    <WixTags
      onClick={tag => onClick(tag.value)}
      items={tags}
      size={SIZE.small}
      skin={SKIN.light}
      className={classes.tags}
    />
  )
}
