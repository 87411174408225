import React from 'react'
import {FloatingDropdown} from 'wix-ui-tpa/FloatingDropdown'
import {PlacesProps} from './index'

export const Places: React.FC<PlacesProps> = ({places}) => {
  if (!places.length) {
    return null
  }
  return <FloatingDropdown options={places as any} label="Filter By:" value={places[0].id} />
}
